<template>
  <div class="smelling-overlay" :style="{ color: textColor }">
    <div class="background" :style="{ backgroundColor: color }"></div>
    <div class="buttons-container">
      <div
        @click="backToHome"
        class="back"
        :style="{ '--border-color': textColor }"
      >
        {{ $t("back_to_home") }}
      </div>
      <div
        @click="onClose"
        class="close"
        :style="{ '--border-color': textColor }"
      >
        {{ $t("see_details") }}
      </div>
    </div>
    <div class="popup">
      <div class="arrow">
        <div :style="{ backgroundColor: textColor }"></div>
        <div :style="{ backgroundColor: textColor }"></div>
        <div :style="{ backgroundColor: textColor }"></div>
      </div>
      <div class="title">{{ $t("discover_smell_perfume") }}</div>
    </div>
  </div>
</template>

<script>
import { WIZARD_V2 } from "../router";
import MachineInterfaceClass, {
  getMachineInstance,
} from "../services/machine/MachineInterfaceClass";
import { pickTextColorBasedOnBgColorSimple } from "../utils/colorUtils";
export default {
  props: {
    ean: String,
    duration: Number,
    color: String,
  },
  components: {},
  mounted() {
    this.unsubscribe = getMachineInstance().listen((res) => {
      if (res.response !== MachineInterfaceClass.CODES.OK)
        console.log(res.comment);
      switch (res.response) {
        case MachineInterfaceClass.CODES.EXE_MAXTIMEREACHED:
        case MachineInterfaceClass.CODES.WAIT_MAXTIMEREACHED:
        case MachineInterfaceClass.CODES.SPRAY_OK:
          break;
        /*return setTimeout(()=>{
            machineInterface.stopPerfume()
            return this.onClose()
          },2000)*/

        case MachineInterfaceClass.CODES.OK:
          break;
        case MachineInterfaceClass.CODES.ERROR_PARAMETERS:
        case MachineInterfaceClass.CODES.PERFUME_DISABLED:
        case MachineInterfaceClass.CODES.ERROR_EXECUTION:
        case MachineInterfaceClass.CODES.API_DISABLED:
        case MachineInterfaceClass.CODES.ERROR_CONNECTMACH:
          // alert(res.comment)
          // machineInterface.stopPerfume();
          // this.onClose();
          break;
      }
    });
    getMachineInstance().startSingle({
      EAN: this.ean,
      testMode: Math.round(this.duration / 1000),
    });
  },
  destroyed() {
    if (this.unsubscribe) this.unsubscribe();
  },
  computed: {
    textColor() {
      return pickTextColorBasedOnBgColorSimple(this.color);
    },
  },
  methods: {
    onClose() {
      getMachineInstance().stopPerfume();
      this.$emit("close");
    },
    backToHome() {
      this.onClose();
      this.$router.push({ name: WIZARD_V2 });
    },
  },
};
</script>

<style scoped lang="stylus">
.smelling-overlay
  height: calc(var(--vh, 1vh) * 100)
  left: 0
  position: fixed
  top: 0
  width: 100vw
  z-index: 999

  .background
    background-color: #fff
    height: 100%
    position: absolute
    width: 100%

  .popup
    align-items: flex-end
    display: flex
    flex-direction: column
    height: 100%
    left: 0
    padding: vh(105px) vw(120px)
    position: absolute
    top: 0
    width: 100%

    .arrow
      animation: arrow
      animation-duration: 4s
      animation-iteration-count: infinite
      flex: 0.1 1 0%
      margin-bottom: vh(40px)
      margin-top: auto
      overflow: hidden
      position: relative
      width: vw(30px)

      @keyframes arrow
        0%
          flex-grow: 0.1

        50%
          flex-grow: 0.6

        100%
          flex-grow: 0.1

      div
        background-color: #000
        left: 50%
        position: absolute
        transform: translateX(-50%)
        width: 2px

        &:nth-child(1)
          height: 20px
          transform: translateX(calc(-50% - 6px)) rotate(35deg)

        &:nth-child(2)
          height: 20px
          transform: translateX(calc(-50% + 6px)) rotate(-35deg)

        &:nth-child(3)
          height: 100%

    .title
      m-font("DIN Engschrift Std")
      m-font-size(65, 70)
      text-align: right
      text-transform: uppercase
      width: 20ch

  .buttons-container
    align-items: center
    display: flex
    height: $header-height
    justify-content: space-between
    left: 0
    padding-left: vw(70px)
    padding-right: vw(70px)
    position: absolute
    top: 0
    width: 100%
    z-index: 1

    .back,
    .close
      m-font("Paco")
      m-font-size(12, 14)
      m-letter-spacing(60)
      cursor: pointer
      left: vw(50px)
      position: absolute
      text-transform: uppercase
      top: vh(26px)
      z-index: 1

      &:after
        background-color: var(--border-color)
        content: ""
        display: block
        height: 1px
        margin-top: vw(7px)
        width: 100%

    .close
      left: auto
      right: vw(50px)
</style>
