<template>
  <div class="perfume-card" @click="$emit('click', $event)">
    <div
      class="top-image"
      :style="{ backgroundImage: `url(${perfume.imageUrl})` }"
    >
      <div
        class="background"
        :style="{ backgroundImage: `url(${perfume.resultPageImageLink})` }"
      />
    </div>
    <div class="bottom">
      <div class="left">
        <div class="title">{{ perfume.name }}</div>
        <!-- <div class="subtitle">{{ perfume.typeOfPerfume }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    perfume: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume-card
  display: flex
  flex-direction: column

  .top-image
    m-keep-ratio("3-4")
    background-blend-mode: multiply
    background-color: #eee
    background-position: center center
    background-repeat: no-repeat
    background-size: 65%
    flex: 1 1 100%
    position: relative

    .background
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      cursor: pointer
      height: 100%
      opacity: 0
      position: absolute
      transition: opacity 0.5s ease-in-out
      width: 100%

      &:hover
        opacity: 1

  .bottom
    display: flex
    justify-content: space-evenly
    padding: vw(20px)

    .left
      flex: 1 1 100%
      text-align: left

      .title
        m-font("Paco")
        m-font-size(15, 20)
        letter-spacing: 0.05em

        .mobile &
          m-font-size(14, 18)

      .subtitle
        .mobile &
          m-font-size(12, 16)
</style>